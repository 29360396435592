/**
 * @todo
 * - Collect the variants with the last x% of cases into one variant
 * - Show if table has focus and allow variant navigation by keyboard
 */

import { Column, Row, useTable } from 'react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import { HTMLTable } from '@blueprintjs/core';
import React from 'react';
import TableScrollbar from './TableScrollbar.js';
import { Variant } from '@insight/common/interface/iVariant.js';
import { GraphInteractionControlProps } from '../View/GraphInteractionController.js';

export type VariantsTableState = {
    selectedVariantsIndexes: number[],
}

export type VariantsTableProps = {
    variants: Variant[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    heading: boolean; // wether to display a heading
} & GraphInteractionControlProps;

interface Data {
    id: number;
    cases: number;
    percent: string;
    minLength: number;
    maxLength: number;
}

const CONTROL_ID = "variants";
const CONTROL_GROUP = "function";

function getUnique<T>(elements: T[]) {
    return [...new Set<T>([...elements])];
}

export function VariantsTable(props: VariantsTableProps) {

    const intl = useIntl();

    const [state, setState] = React.useState<VariantsTableState>(() => {
        return {
            selectedVariantsIndexes: [],
        }
    });

    React.useEffect(
        () => {
            props.interactionController.registerSelector(CONTROL_ID, {
                category: CONTROL_GROUP,
                isInGraphControl: false,
                setState: (data) => {
                    setState({ ...state, selectedVariantsIndexes: data ? data as number[] : [] })
                }
            })
        },
        [props.variants]
    )

    React.useEffect(() => {
        console.log(`*** Render VariantsTable`);
    })

    React.useEffect(() => {
        console.log('*** select initial')
        if (props.variants != undefined && props.variants.length > 0) {
            doSelect([0]);
        }
    }, [props.variants])

    const doSelect = (selectedVariantsIndexes: number[]) => {
        const caseIds: number[] = getUnique(selectedVariantsIndexes.flatMap(variantIdx => props.variants[variantIdx].cases));
        props.interactionController.select({
            caseIds,
            controlId: CONTROL_ID,
            controlState: [...selectedVariantsIndexes],
        });
        setState({ ...state, selectedVariantsIndexes });
    }

    const handleRowClick = (rowIdx: number, event: React.MouseEvent<HTMLElement>) => {
        let selectedVariantsIndexes = state.selectedVariantsIndexes;
        const iPos = selectedVariantsIndexes.indexOf(rowIdx);
        if (event?.ctrlKey) {
            if (iPos >= 0) {
                selectedVariantsIndexes.splice(iPos, 1); // already selected, remove
            } else {
                selectedVariantsIndexes.push(rowIdx); // select
            }
        }
        else if (event?.shiftKey && selectedVariantsIndexes.length !== 0) {
            const minRowSelected = Math.min(...selectedVariantsIndexes, rowIdx);
            const maxRowSelected = Math.max(...selectedVariantsIndexes, rowIdx);
            selectedVariantsIndexes = [];
            for (let r = minRowSelected; r <= maxRowSelected; r++) { selectedVariantsIndexes.push(r); }
        }
        else {
            if (iPos >= 0) {
                selectedVariantsIndexes = [];
            }
            else {
                selectedVariantsIndexes = [rowIdx];
            }
        }

        doSelect(selectedVariantsIndexes);
    }

    if (props.variants) {
        const createColumns: () => Column<Data>[] = () => {
            return [
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.nr",
                        defaultMessage: "No.",
                        description: "Label of for serial number of a variant."
                    }),
                    accessor: 'id'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.amount",
                        defaultMessage: "#",
                        description: "Label for amount of cases in a variant."
                    }),
                    accessor: 'cases'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.percent",
                        defaultMessage: "%",
                        description: "Label for % of total cases in this variant."
                    }),
                    accessor: 'percent'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.min_events",
                        defaultMessage: "min. # events",
                        description: "Label for minimal number of events in variant cases."
                    }),
                    accessor: 'minLength'
                },
                {
                    Header: intl.formatMessage({
                        id: "variantstable.columns.max_events",
                        defaultMessage: "max. # events",
                        description: "Label for maximal number of events in variant cases."
                    }),
                    accessor: 'maxLength'
                },
            ]
        }

        const createTableData: () => Data[] = () => {
            const amountOfCases = props.variants.reduce((sum: number, v) => (sum += v.cases.length), 0);
            const data: Data[] = props.variants.slice(0, 5000).map((v, i) => ({ /** @todo: make 1000 max variant entries a configuration parameter */
                id: i + 1,
                cases: v.cases.length,
                percent: (Math.round(v.cases.length / amountOfCases * 10000) / 100).toFixed(2),
                minLength: v.minLength,
                maxLength: v.maxLength,
            }));
            return data;
        }

        const c = React.useMemo(createColumns, []);
        const d = React.useMemo(createTableData, [props.variants]);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns: c, data: d });

        const createRowProps = (tableRow: Row<Data>, idx: number) => {
            const result = {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    handleRowClick(tableRow.index, event)
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                },
                style: {
                    cursor: "pointer"
                },
                className: ""
            }
            if (state.selectedVariantsIndexes.includes(idx)) {
                result.className = "selected"
            }
            return result;
        };

        return (
            <div id='variants-table' className="selector">
                {props.heading && <h2 className="bp5-heading">
                    <FormattedMessage
                        id="view.variants.ttl"
                        defaultMessage="{amount} flow variants"
                        description="Label with number of loaded variants."
                        values={{ amount: props.variants.length }}
                    />
                </h2>}

                <TableScrollbar>
                    <HTMLTable {...getTableProps()} compact={true} bordered={true}>
                        <thead>
                            {headerGroups.map((headerGroup, j) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={"k" + j} >
                                    {headerGroup.headers.map((column, i) => (
                                        <th {...column.getHeaderProps()} key={"k" + i}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, row_idx) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps(createRowProps(row, row_idx))} key={"r" + row_idx}>
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td {...cell.getCellProps()} key={"c" + i}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </HTMLTable>
                </TableScrollbar>
            </div>
        )
    }
    else {
        return <div>No variants data</div>
    }
}
