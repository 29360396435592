import { Duration, DateTime, DurationUnit, DateTimeUnit } from "luxon";
type ExtDurationUnit = DurationUnit | "decades";
type ExtDateTimeUnit = DateTimeUnit | "decade";

abstract class TimeUnit {
    static unitLabel: string;
    static unit_in_ms: number;
    static unitPlural: ExtDurationUnit;
    static unitSingular: ExtDateTimeUnit;
    static formatTimestamp: (d: number, locale: string) => string;
    static maxUnit: number;
    static unitSteps: number[];
    static list: (typeof TimeUnit)[];

    static determineAxisUnitAndIntervalSize(axisTimeSpan: number, maxIntervals: number): { timeUnit: typeof TimeUnit, intervalSize: number } {
        let intervalSize: number | undefined = undefined
        const timeUnit: typeof TimeUnit | undefined = this.list.find(timeUnit => {
            if (axisTimeSpan < timeUnit.maxUnit * timeUnit.unit_in_ms ) {
                intervalSize = timeUnit.unitSteps.find(step => {
                    const intervals = axisTimeSpan / (step * timeUnit.unit_in_ms);
                    const result = intervals <= maxIntervals
                    return result;
                });
                if (intervalSize) {
                    intervalSize *= timeUnit.unit_in_ms;
                    return true;
                }
            }
            return false;
        })
        if (timeUnit && intervalSize)
            return { timeUnit, intervalSize }
        else
            return { timeUnit: MilliSecond, intervalSize: 1 }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getUnits(minTime: number, maxTime: number): number {
        if (this.unitPlural !== "decades") {
            return Duration.fromMillis(maxTime - minTime).as(this.unitPlural)
        }
        else {
            return Duration.fromMillis(maxTime - minTime).as("years") / 10;
        }
    }

    /**
     * @param timeStamp time in ms
     * @returns the time of the time unit belonging to the timestamp, e.g. 00:00 of the first day of the month, if month is the unit.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getTimeUnit(timeStamp: number): number {
        if (this.unitSingular !== "decade") {
            return DateTime.fromMillis(timeStamp).startOf(this.unitSingular).toMillis()
        }
        else {
            const dt = DateTime.fromMillis(timeStamp);
            return dt.startOf('year').set({ year: Math.floor(dt.year / 10) * 10 }).toMillis();
        }
    }

    static formatDuration = (d: number, locale: string) => {
        return Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(Math.floor(d / this.unit_in_ms))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getDuration(ms: number) {
        return ms / this.unit_in_ms
    }
}

/**
 * A time  class for seconds time intervals
 */
class MilliSecond extends TimeUnit {
    static unit_in_ms = 1;
    static unitLabel = "ms";
    static unitPlural: ExtDurationUnit = "milliseconds"
    static unitSingular: ExtDateTimeUnit = "millisecond";

    static formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            fractionalSecondDigits: 3,
            hour12: false,
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static formatDuration = (d: number, locale: string) => {
        return d.toString();
    }
    static maxUnit = 5000;
    static unitSteps = [1, 2, 5, 10, 50, 100, 200]
}

/**
 * A time  class for seconds time intervals
 */
class Second extends TimeUnit {
    static unit_in_ms = 1000;
    static unitLabel = "s";
    static unitPlural: ExtDurationUnit = "seconds"
    static unitSingular: ExtDateTimeUnit = "second";

    static formatTimestamp = (t: number, locale: string) => {
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
        }

        options = (t % this.unit_in_ms !== 0)
            ? { ...options, fractionalSecondDigits: 3 }
            : { ...options }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static formatDuration = (d: number, locale: string) => {
        return Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.floor(d / this.unit_in_ms))
    }

    static maxUnit = 180;
    static unitSteps = [0.25, 0.5, 1, 2, 5, 10, 15, 20, 30]
}

/**
 * A time  class for minutes time intervals
 */
class Minute extends TimeUnit {
    static unit_in_ms = Second.unit_in_ms * 60;
    static unitLabel = "mi";
    static unitPlural: ExtDurationUnit = "minutes"
    static unitSingular: ExtDateTimeUnit = "minute";

    static formatTimestamp = (t: number, locale: string) => {
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        }

        options = (t % this.unit_in_ms !== 0)
            ? { ...options, second: "numeric", hour12: false }
            : options
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static formatDuration = (d: number, locale: string) => {
        d = d - d % Second.unit_in_ms;
        const secs = d % this.unit_in_ms;
        const mins = (d - secs) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale,{maximumFractionDigits:0}).format(mins)
        if (secs !== 0) {
            result += Duration.fromMillis(secs).toFormat(":ss")
        }
        return result
    }

    static maxUnit = 180;
    static unitSteps = [0.25, 0.5, 1, 2, 5, 10, 15, 20, 30]
}

/**
 * A time  class for hours time intervals
 */
class Hour extends TimeUnit {
    static unit_in_ms = Minute.unit_in_ms * 60;
    static unitLabel = "h";
    static unitPlural: ExtDurationUnit = "hours"
    static unitSingular: ExtDateTimeUnit = "hour";

    static formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        }

        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static formatDuration = (d: number, locale: string) => {
        const mins = d % this.unit_in_ms;
        const hrs = (d - mins) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale,{maximumFractionDigits:0}).format(hrs)
        if (mins !== 0) {
            result += Duration.fromMillis(mins).toFormat(":mm")
        }
        return result
    }

    static maxUnit = 48;
    static unitSteps = [0.25, 0.5, 1, 2, 3, 6]
}

/**
 * A time  class for days time intervals
 */
class Day extends TimeUnit {
    static unit_in_ms = 24 * Hour.unit_in_ms
    static unitLabel = "d";
    static unitPlural: ExtDurationUnit = "days"
    static unitSingular: ExtDateTimeUnit = "day";

    static formatTimestamp = (t: number, locale: string) => {
        t = t - t % Hour.unit_in_ms;
        const timeInDay = t % Hour.unit_in_ms;
        let options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        }

        if (timeInDay !== 0) {
            options = {
                ...options,
                hour: "numeric",
                minute: "numeric",
                hour12: false,
            }
        }

        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static formatDuration = (d: number, locale: string) => {
        const hrs = d % this.unit_in_ms;
        const days = (d - hrs) / this.unit_in_ms;
        let result = Intl.NumberFormat(locale,{maximumFractionDigits:0}).format(days)
        if (hrs !== 0) {
            result += Duration.fromMillis(hrs).toFormat(":hh:mm")
        }
        return result
    }

    static maxUnit = 90;
    static unitSteps = [0.25, 0.5, 1, 7]
}

/**
 * A time  class for weeks time intervals
 */
class Week extends TimeUnit {
    static unit_in_ms = 7 * Day.unit_in_ms;
    static unitLabel = "w";
    static unitPlural: ExtDurationUnit = "weeks"
    static unitSingular: ExtDateTimeUnit = "week";

    static formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
        }
        return DateTime.fromMillis(t).weekNumber.toString() + "-"+ Intl.DateTimeFormat(locale, options).format(t);
    }

    static maxUnit = 52
    static unitSteps = [1, 2, 4];
}

/**
 * A time  class for months time intervals
 */
class Month extends TimeUnit {
    static unit_in_ms = Day.unit_in_ms * 365 / 12;
    static unitLabel = "m";
    static unitPlural: ExtDurationUnit = "months"
    static unitSingular: ExtDateTimeUnit = "month";

    static formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            month: "numeric",
            year: "numeric",
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static maxUnit = 48
    static unitSteps = [1, 2];
}

/**
 * A time  class for months time intervals
 */
class Year extends TimeUnit {
    static unit_in_ms = Day.unit_in_ms * 365;
    static unitLabel = "y";
    static unitPlural: ExtDurationUnit = "years"
    static unitSingular: ExtDateTimeUnit = "year";

    static formatTimestamp = (t: number, locale: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
        }
        return Intl.DateTimeFormat(locale, options).format(t);
    }

    static maxUnit = 50
    static unitSteps = [1, 2, 5, 10];
}

/**
 * A time  class for months time intervals
 */
class Decade extends TimeUnit {
    static unit_in_ms = Year.unit_in_ms * 10;
    static unitLabel = "c";
    static unitPlural: ExtDurationUnit = "decades"
    static unitSingular: ExtDateTimeUnit = "decade";

    static formatTimestamp = (t: number, locale: string) => {
        const decadeStart = t - t % this.unit_in_ms;
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
        }
        return Intl.DateTimeFormat(locale, options).format(decadeStart);
    }
    static maxUnit = 50
    static unitSteps = [1, 2, 5, 10];
}

/**
 * a list of possible x-axis resolutions
 */
TimeUnit.list = [
    MilliSecond,
    Second,
    Minute,
    Hour,
    Day,
    Week,
    Month,
    Year,
    Decade
]

export default TimeUnit;
export { MilliSecond, Second, Minute, Hour, Day, Week, Month, Year, Decade }