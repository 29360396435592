import DataController, { DataControllerOptions } from './DataController.js';
import Decycler, { Serializable } from "@insight/common/decycler/decycler.js";
import setClassName from '@insight/common/decycler/setClassName.js';
import { ISOrg } from "@insight/common/orgstructure/ISOrg.js";
import { OrgStructure } from '@insight/common/orgstructure/OrgStructure.js';
OrgStructure;

class OrgStructureController extends DataController<ISOrg| null> {

    constructor(options: DataControllerOptions) {
        super(null, options);
    }

    static getDecycler() {
        const decycler = new Decycler();
        return decycler;
    }

    toJSON() {
        const decycler = OrgStructureController.getDecycler();
        const result = decycler.decycle(this.data);
        return result;
    }

    fromJSON(pojso: Serializable): ISOrg {
        const decycler = OrgStructureController.getDecycler();
        const result: ISOrg = decycler.retrocycle(pojso) as ISOrg;
        return result;
    }
}
setClassName(OrgStructureController, "OrgStructureController"); // for minifying purposes
export default OrgStructureController;