import { FormGroup, InputGroup, MenuItem } from "@blueprintjs/core";
import React from "react";
import { IntlShape } from "react-intl";
import { CaseFilterField, CaseFilterFieldValue } from "./CaseFilterField.js";
import { MultiSelect, Suggest } from "@blueprintjs/select";
import Decycler from "@insight/common/decycler/decycler.js";
import setClassName from '@insight/common/decycler/setClassName.js';

export enum FieldType {
    BOOLEAN = 1,
    INTEGER,
    FLOAT,
    STRING,
    DURATION,
    DATE,
}

export type CaseFilterFieldDefinitionData = {
    id: string,
    name: (intl: IntlShape) => string;
    description: (intl: IntlShape) => string;
    placeholder?: (intl: IntlShape) => string;
    required: boolean;
    vector: boolean;
    visible: boolean;
    type: FieldType;
    format?: string;
    initialValue?: CaseFilterFieldValue
}

export type CaseFilterFieldDefinitions = CaseFilterFieldDefinition[];
export class CaseFilterFieldDefinition implements CaseFilterFieldDefinitionData {

    id!: string;
    name!: (intl: IntlShape) => string;
    description!: (intl: IntlShape) => string;
    placeholder?: (intl: IntlShape) => string;
    required!: boolean;
    vector!: boolean;
    visible!: boolean;
    type!: FieldType;
    format?: string;
    initialValue?: CaseFilterFieldValue

    constructor(data: CaseFilterFieldDefinitionData) {
        Object.assign(this, data);
    }

    getChangedProperties(def: CaseFilterFieldDefinition): Partial<CaseFilterFieldDefinitionData> {
        const result: Partial<CaseFilterFieldDefinitionData> = {};
        if (def.required !== undefined && this.required !== def.required) result.required = def.required;
        if (def.vector !== undefined && this.vector !== def.vector) result.vector = def.vector;
        if (def.visible !== undefined && this.visible !== def.visible) result.visible = def.visible;
        if (def.type !== undefined && this.type !== def.type) result.type = def.type;
        if (def.format !== undefined && this.format !== def.format) result.format = def.format;
        if (def.initialValue !== undefined && this.initialValue !== def.initialValue) result.initialValue = def.initialValue;
        return result;
    }

    getComponent(field: CaseFilterField,
        setField: (key: string, field: CaseFilterField) => void,
        intl: IntlShape,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ) {
        field = field.copy();
        const tmp = field.getUIValue();
        const guiValue: string = !Array.isArray(tmp) ? tmp || "" : 'Unexpected array value'
        return (<FormGroup
            label={this.name(intl)}
            helperText={field.valid ? this.description(intl) : field.reason}
            labelFor={this.id}
            labelInfo={this.required
                ? intl.formatMessage({
                    id: "dialog.label.required",
                    defaultMessage: "(required)",
                    description: "Label info for a required field.",
                })
                : undefined}
            key={this.id}
        >
            <InputGroup
                autoComplete="off"
                id={this.id}
                placeholder={this.placeholder === undefined ? undefined : this.placeholder(intl)}
                value={guiValue}
                intent={field.valid ? "none" : "danger"}
                onChange={event => {
                    field.fromString(event.target.value); // set
                    setField(this.id, field);
                    if (field.filter.onChange !== undefined) {
                        field.filter.onChange(field, event.target.value);
                    }
                }}
            />
        </FormGroup>)
    }
}
setClassName(CaseFilterFieldDefinition, "CaseFilterFieldDefinition")
Decycler.registerSerializableType(CaseFilterFieldDefinition)


export type CaseFilterRangeFieldData = CaseFilterFieldDefinitionData & { range?: { min: number, max: number } };
export class CaseFilterRangeFieldDefinition extends CaseFilterFieldDefinition implements CaseFilterRangeFieldData {
    range: { min: number, max: number } | undefined;
    constructor(data: CaseFilterRangeFieldData) {
        super(data)
        this.range = data.range;
    }
}
setClassName(CaseFilterRangeFieldDefinition, "CaseFilterRangeFieldDefinition");
Decycler.registerSerializableType(CaseFilterRangeFieldDefinition)

export type CaseFilterMatchFieldData = CaseFilterFieldDefinitionData & { regex?: string };
export class CaseFilterMatchFieldDefinition extends CaseFilterFieldDefinition implements CaseFilterMatchFieldData {
    regex: string | undefined;
    constructor(data: CaseFilterMatchFieldData) {
        super(data)
        this.regex = data.regex;
    }
}
setClassName(CaseFilterMatchFieldDefinition, "CaseFilterMatchFieldDefinition");
Decycler.registerSerializableType(CaseFilterFieldDefinition)

export class CaseFilterChoicesFieldDefinition extends CaseFilterFieldDefinition {
    getComponent(field: CaseFilterField,
        setField: (key: string, field: CaseFilterField) => void,
        intl: IntlShape,
    ) {
        const guiValue = !Array.isArray(field.getUIValue()) ? field.getUIValue() as string : 'Unexpected array value'
        return (<FormGroup
            label={this.name(intl)}
            helperText={field.valid ? this.description(intl) : field.reason}
            labelFor={this.id}
            labelInfo={this.required
                ? intl.formatMessage({
                    id: "dialog.label.required",
                    defaultMessage: "(required)",
                    description: "Label info for a required field.",
                })
                : undefined}
            key={this.id}
        >
            <Suggest<string>
                key={this.id}
                query={guiValue}
                inputValueRenderer={item => item}
                items={field.filter.getOptions(field)}
                itemPredicate={(query, item) => item.toLowerCase().includes(query.toLowerCase())}
                itemRenderer={(item, props) => props.modifiers.matchesPredicate ?
                    <MenuItem
                        ref={props.ref}
                        key={item}
                        active={props.modifiers.active}
                        disabled={props.modifiers.disabled}
                        text={item}
                        selected={item == guiValue}
                        onClick={props.handleClick}
                        onFocus={props.handleFocus}
                        roleStructure='listoption'
                    /> : null}

                onItemSelect={item => {
                    field.fromString(item);
                    setField(this.id, field);
                    if (field.filter.onChange !== undefined) {
                        field.filter.onChange(field, item);
                    }
                }}
                popoverProps={{ minimal: true, matchTargetWidth: true }}

            ></Suggest>
        </FormGroup>)
    }
}
setClassName(CaseFilterChoicesFieldDefinition, "CaseFilterChoicesFieldDefinition");
Decycler.registerSerializableType(CaseFilterChoicesFieldDefinition);


export class CaseFilterSelectFieldDefinition extends CaseFilterFieldDefinition {
    selectedItems: string[] = [];
    getComponent(field: CaseFilterField,
        setField: (key: string, field: CaseFilterField) => void,
        intl: IntlShape,
    ) {
        // const guiValue = !Array.isArray(field.getUIValue()) ? field.getUIValue() as string : 'Unexpected array value'
        if (field.value !== undefined) {
            this.selectedItems = field.value as string[];
        }
        return (<FormGroup
            label={this.name(intl)}
            helperText={field.valid ? this.description(intl) : field.reason}
            labelFor={this.id}
            labelInfo={this.required
                ? intl.formatMessage({
                    id: "dialog.label.required",
                    defaultMessage: "(required)",
                    description: "Label info for a required field.",
                })
                : undefined}
            key={this.id}
        >
            <MultiSelect<string>
                key={this.id}
                resetOnSelect
                // query={guiValue}
                // inputValueRenderer={item => item}
                items={field.filter.getOptions(field) || []}
                itemPredicate={(query, item) => item.toLowerCase().includes(query.toLowerCase())}
                itemRenderer={(item, props) => props.modifiers.matchesPredicate ?
                    <MenuItem
                        ref={props.ref}
                        key={item}
                        active={props.modifiers.active}
                        disabled={props.modifiers.disabled}
                        text={item}
                        // selected={item == guiValue}
                        onClick={props.handleClick}
                        onFocus={props.handleFocus}
                        roleStructure='listoption'
                    /> : null}
                tagRenderer={(item) => item}
                onItemSelect={item => {
                    if (!this.selectedItems.includes(item)) this.selectedItems.push(item);
                    field.fromString(this.selectedItems);
                    setField(this.id, field);
                    if (field.filter.onChange !== undefined) {
                        field.filter.onChange(field, item);
                    }
                }}
                onRemove={item => {
                    const idx = this.selectedItems.findIndex(v => v === item);
                    if (idx >= 0) {
                        this.selectedItems.splice(idx, 1);
                    }
                    field.fromString(this.selectedItems);
                    setField(this.id, field);
                    if (field.filter.onChange !== undefined) {
                        field.filter.onChange(field, item);
                    }
                }}
                selectedItems={this.selectedItems}
                popoverProps={{ minimal: true, matchTargetWidth: false, popoverClassName: "drop-down" }}

            ></MultiSelect>
        </FormGroup>)
    }
}
setClassName(CaseFilterChoicesFieldDefinition, "CaseFilterChoicesFieldDefinition");
Decycler.registerSerializableType(CaseFilterChoicesFieldDefinition);
