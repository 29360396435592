import React from 'react'

import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";

import { AuthProvider, Protector } from '../../components/authentication/json/AuthenticationProvider.js';
import { Homepage } from '../../components/Homepage.js';
import MainEntrance from '../../components/MainEntrance.js';
import { IntlShape } from 'react-intl';
import authProviderService from '../../authentication/strategy/AuthProviderService.js';
import AzureAuthentificator from './azure/AzureAuthentificator.js';


export interface BaseplateAuthStrategyComponentProviderProps extends React.JSX.IntrinsicAttributes {
    intl: IntlShape;
}
export const BaseplateAuthStrategyComponentProvider = (props: BaseplateAuthStrategyComponentProviderProps) => {

    return (<>
        {authProviderService.isAzureStrategy() ?
            (
                <AzureAuthentificator>
                    <Homepage />
                </AzureAuthentificator>
            ) : (
                <AuthProvider loginComponent={MainEntrance}>
                    <Router >
                        <Routes>
                            <Route path="/" element={<Protector component={Homepage} props={""} />} />
                            <Route element={<Navigate to="/" />} />
                        </Routes>
                    </Router>
                </AuthProvider>
            )}
    </>)
}
