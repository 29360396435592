import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilterFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilter } from './CaseFilter.js';
import setClassName from '@insight/common/decycler/setClassName.js';
import Decycler from '@insight/common/decycler/decycler.js';
import { IntlShape } from 'react-intl';

export class CaseFilterEventCount extends CaseFilter {

    execute(eventsfile: string) {
        const query = new URLSearchParams({
            eventsfile,
        });
        return sendRequest<number[], { [key: string]: unknown }>("event_count_filter?" + query.toString(), {
            method: "post",
            data: this.fieldValues(),
        })
    }

    label(intl: IntlShape) {
        let msg: string;
        if (this.fields.min && this.fields.max) {
            msg = intl.formatMessage({
                id: "filter.msg.event_count",
                defaultMessage: "{min} ≤ # events ≤ {max}",
                description: "Describe boundaries of EventCount filter.",
            }, this.fieldValues()) as string
        }
        else if (this.fields.min) {
            msg = intl.formatMessage({
                id: "filter.msg.event_count.min_only",
                defaultMessage: "# events ≥ {min}",
                description: "Describe min bounday of EventCount filter.",
            }, this.fieldValues()) as string
        }
        else if (this.fields.max) {
            msg = intl.formatMessage({
                id: "filter.msg.event_count.max_only",
                defaultMessage: "# events ≤ {max}",
                description: "Describe max boundary of EventCount filter.",
            }, this.fieldValues()) as string
        }
        else {
            msg = intl.formatMessage({
                id: "filter.msg.event_count.missing_parameters",
                defaultMessage: "Filter needs min and/ or max parameters",
                description: "Describe missing paarameters EventCount filter.",
            })
        }
        return msg;
    }

}
setClassName(CaseFilterEventCount, "CaseFilterEventCount"); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterEventCount);

new CaseFilterType({
    id: "eventcount",
    name: intl => intl.formatMessage({
        id: "filter.def.eventcount.name",
        defaultMessage: "Eventcount",
        description: "Name of eventcount filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.eventcount.description",
        defaultMessage: "Limit cases to amount of events",
        description: "Description of eventcount filter.",
    }),
    fieldDefinitions: [
         new CaseFilterFieldDefinition({
            id: "min",
            name: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.min.name",
                defaultMessage: 'Min. amount',
                description: "Name of min parameter for eventcount filter.",
            }),
            required: true,
            visible: true,
            type: FieldType.INTEGER,
            description: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.min.description",
                defaultMessage: 'The least amount of events to qualify a case. 0 if empty.',
                description: "Description of min parameter for eventcount filter.",
            }),
            placeholder: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.min.placeholder",
                defaultMessage: 'An integer number like 1, 5 or 234 ...',
                description: "Placeholder of min parameter for eventcount filter.",
            }),
            vector: false,
        }),
        new CaseFilterFieldDefinition({
            id: "max",
            name: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.max.name",
                defaultMessage: 'Max. amount',
                description: "Name of max parameter for eventcount filter.",
            }),
            required: true,
            type: FieldType.INTEGER,
            visible: true,
            description: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.max.description",
                defaultMessage: 'The maximal amount of events to qualify a case. 0 if empty.',
                description: "Description of max parameter for eventcount filter.",
            }),
            placeholder: intl => intl.formatMessage({
                id: "filter.def.eventcount.parameter.max.placeholder",
                defaultMessage: 'An integer number like 1, 5 or 234 ...',
                description: "Placeholder of max parameter for eventcount filter.",
            }),
            vector: false,
        }),
    ],
    childrenAllowed: false,
    icon: <Icon icon="filter"/>,
    class: CaseFilterEventCount,
})
