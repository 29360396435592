import React from 'react';
import { useActor } from "@xstate/react";
import { Grid } from './Grid/Grid.js';
import { View } from './View/View.js';
import displayModeService from '../classes/displayModeService.js';
import { DataContext } from './DataProvider2.js';

export const Homepage: React.FC = () => {

    React.useEffect(()=>{
        console.log(`*** Render Homepage`);
    })

    const [displayMode] = useActor(displayModeService);
    const single = displayMode.matches("single");
    const components = [];

    const dataContext = React.useContext(DataContext);

    if (dataContext !== undefined) {
        if (single) {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns={5} rows={10}>
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataContext}
                        />
                    </Grid>
                </div>
            )
        }
        else {
            components.push(
                <div id="desk" key={components.length + 1}>
                    <Grid columns={10} rows={10}>
                        <View
                            id="view_1"
                            top={1}
                            left={1}
                            bottom={10}
                            right={5}
                            key="1"
                            dataProvisionContext={dataContext}
                        />
                        <View
                            id="view_2"
                            top={1}
                            left={6}
                            bottom={10}
                            right={10}
                            key="2"
                            dataProvisionContext={dataContext}
                        />
                    </Grid>
                </div>
            )
        }
    }

    // if (dataProvisionState.matches('error') && dataProvisionState.context.error) {
    //     components.push(
    //         <Dialog
    //             key={components.length + 1}
    //             title={intl.formatMessage({
    //                 defaultMessage: "An error occured.",
    //                 description: "A dialog title to inform the user that an error occured. Details are in the dialog"
    //             })}
    //             isOpen={true}
    //         >
    //             <div className={Classes.DIALOG_BODY}>
    //                 <p>{dataProvisionState.context.error.message}</p>
    //             </div>
    //             <div className={Classes.DIALOG_FOOTER}>
    //                 <div className={Classes.DIALOG_FOOTER_ACTIONS}>
    //                     <Button onClick={() => dataProvisionSend('confirm_error')}>Confirm</Button>
    //                 </div>
    //             </div>
    //         </Dialog>
    //     )
    // }

    return (<>
        {components}
    </>)
}