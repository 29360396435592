import { IntlShape } from "react-intl";
import setClassName from '@insight/common/decycler/setClassName.js';
import SortedMap from "collections/sorted-map.js";
import { CaseFilter } from "./CaseFilter.js";
import Decycler from "@insight/common/decycler/decycler.js";
import { CaseFilterFieldDefinitions } from "./CaseFilterFieldDefinition.js";

export type CaseFilterDefinition = (intl: IntlShape) => CaseFilterType;

type CaseFilterTypeData = {
    id: string;
    name: (intl: IntlShape) => string;
    description: (intl: IntlShape) => string;
    fieldDefinitions: CaseFilterFieldDefinitions;
    childrenAllowed: boolean;
    icon: JSX.Element;
    class: typeof CaseFilter;
}

export class CaseFilterType implements CaseFilterTypeData {

    static types = new SortedMap<string, CaseFilterType>();
    static getTypes(): CaseFilterType[] {
        return Array.from(CaseFilterType.types.values());
    }

    /** for decycler to work */
    static getById(id: string) {
        return CaseFilterType.types.get(id);
    }

    id!: string;
    name!: (intl: IntlShape) => string;
    description!: (intl: IntlShape) => string;
    fieldDefinitions: CaseFilterFieldDefinitions = [];
    childrenAllowed!: boolean;
    icon!: JSX.Element;
    class!: typeof CaseFilter

    constructor(data: CaseFilterTypeData) {
        Object.assign(this, data);
        CaseFilterType.types.set(this.id, this);
    }

    renderComponents() {
        return;
    }
}
setClassName(CaseFilterType, "CaseFilterType");
Decycler.registerBoundaryType(CaseFilterType);