import { RunTime } from './run_time.js';
/**
 * An EdgeCase is a case which runs 1 ... n times along an edge,
 * each time with a different runtime
 */
export class EdgeCase {
    case_id: number;
    runtimes: Array<RunTime>; // the individual runtimes of this case on this edge
    count: number; // number of times this case runs over this edge
    __runtime: number; // cache for total runtime of this case on this edge, including repetitions

    constructor(id: number, rt: Array<RunTime>) {
        this.case_id = id;
        this.runtimes = rt;
        this.count = 0;
        this.__runtime = 0;
    }

    get minRuntime() {
        return Math.min(...this.runtimes.map(rt=>rt.ms));
    }

    get maxRuntime() {
        return Math.max(...this.runtimes.map(rt=>rt.ms))
    }

    /**
     * returns the total runtime a case spends on a specific edge.
     */
    get totalRuntime() {
        const len = this.runtimes.length;
        /** recalculate runtime if length of array was changed */
        if (this.count !== len) {
            this.count = len;
            this.__runtime = this.runtimes.reduce((crt, rt) => crt + rt.ms, 0);
        }
        return this.__runtime;
    }
}
