import React from "react";
import { Tree, TreeEventHandler, TreeNodeInfo } from "@blueprintjs/core";
import { OrgStructure } from "@insight/common/orgstructure/OrgStructure.js";
import { sendRequest } from "../classes/Request.js";
import Project from "@insight/common/interface/Project.js";
import { ISOrg } from "@insight/common/orgstructure/ISOrg.js";
import { CaseAttributeFilterParameters } from "@insight/common/interface/CaseFilterInterface.js";
import { GraphInteractionControlProps } from "./View/GraphInteractionController.js";

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
type OrgStructureTreeNodeInfo = WithRequired<TreeNodeInfo<OrgStructure>, "nodeData">;

export type OrgTreeProps = {
    isorg: ISOrg;
    project: Project;
} & GraphInteractionControlProps;

const CONTROL_ID = "orgtree";

export function OrgTree(props: OrgTreeProps) {
    const [expandedOrgIds, setExpandedOrgIds] = React.useState<Set<number>>(new Set<number>());
    const [selectedOrgIds, setSelectedOrgIds] = React.useState<Set<number>>(new Set<number>());

    React.useEffect(() => {
        props.interactionController.registerSelector(CONTROL_ID, {
            category: "org",
            isInGraphControl: false,
            setState: (data: unknown) => {
                setSelectedOrgIds(new Set<number>(data ? data as number[] : []))
            }
        })
        setSelectedOrgIds(new Set<number>([]));
    }, [])

    const doSelect = (selectedOrgIds: Set<number>) => {
        const query = new URLSearchParams({
            eventsfile: props.project.eventsFilePath,
        });
        const data: CaseAttributeFilterParameters = {
            attributeName: props.isorg.attribute,
            operator: "in",
            attributeArrayValue: props.isorg.orgs
                .map(org => org.findOrgsById([...selectedOrgIds])
                    .map(org => org.getLeafs()).flat()
                    .map(leaf => leaf.name))
                .flat(),
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (data.attributeArrayValue!.length > 0) {
            sendRequest<number[], { [key: string]: unknown }>("case_attribute_filter?" + query.toString(), {
                method: "post",
                data
            }).then(caseIds => {
                props.interactionController.select({
                    caseIds,
                    controlId: CONTROL_ID,
                    controlState: new Set<number>(selectedOrgIds),
                })
            })
        }
        else {
            props.interactionController.select({
                caseIds: [],
                controlId: CONTROL_ID,
                controlState: new Set<number>(selectedOrgIds),
            })
        }
    }

    // deslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleNodeCollapse = React.useCallback((node: OrgStructureTreeNodeInfo) => {
        setExpandedOrgIds(new Set(Array.from(expandedOrgIds).filter(id => id !== node.nodeData.id)))
    }, [expandedOrgIds]);

    const handleNodeExpand = React.useCallback((_node: OrgStructureTreeNodeInfo) => {
        if (_node.nodeData !== undefined) {
            setExpandedOrgIds(new Set([...Array.from(expandedOrgIds), _node.nodeData.id]))
        }
    }, [expandedOrgIds]);

    const handleClick = React.useCallback((_node: OrgStructureTreeNodeInfo, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        const target: Element = e.target as Element;
        if (target.className.includes("tree-label")) {
            if (selectedOrgIds.has(_node.nodeData.id)) {
                if (e.ctrlKey) {
                    selectedOrgIds.delete(_node.nodeData.id)
                }
                else {
                    selectedOrgIds.clear();
                }
            }
            else {
                if (e.ctrlKey) {
                    selectedOrgIds.add(_node.nodeData.id);
                }
                else {
                    selectedOrgIds.clear();
                    selectedOrgIds.add(_node.nodeData.id);
                }
            }
            doSelect(selectedOrgIds);
            setSelectedOrgIds(new Set(Array.from(selectedOrgIds)));
        }
    }, []);

    function nodelist(orgs: OrgStructure[], selectedOrgIds: Set<number>): OrgStructureTreeNodeInfo[] {
        return orgs.map<OrgStructureTreeNodeInfo>(org => {
            let tni: OrgStructureTreeNodeInfo = {
                id: org.id,
                label: <div className="tree-label">{org.name} {org.description ? " - " + org.description : ""}</div>,
                isSelected: selectedOrgIds.has(org.id),
                nodeData: org
            }
            if (org.children.size > 0) {
                tni = {
                    ...tni,
                    isExpanded: expandedOrgIds.has(org.id),
                    hasCaret: true,
                    childNodes: nodelist(Array.from(org.children), selectedOrgIds)
                }
            }
            return tni;
        })
    }

    const nodes = React.useMemo(() => {
        return nodelist(props.isorg.orgs, selectedOrgIds);
    }, [props.isorg, selectedOrgIds, expandedOrgIds]);

    const result = <div id="org-tree" className="selector">
        <Tree<OrgStructure>
            contents={nodes}
            onNodeExpand={handleNodeExpand as TreeEventHandler<OrgStructure>}
            onNodeCollapse={handleNodeCollapse as TreeEventHandler<OrgStructure>}
            onNodeClick={handleClick as TreeEventHandler<OrgStructure>}
        />
    </div>
    return result;
}