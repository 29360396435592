import DataController from './DataController.js';
import setClassName from '@insight/common/decycler/setClassName.js';
import {Variant} from "@insight/common/interface/iVariant.js";

/**
 * Reminder: the class name determines the index into the array of filenames in the DataProvider class
 * which are requested first.
 */
class VariantsController extends DataController<Variant[]> {
    constructor() {
        super([]);
    }
}
setClassName(VariantsController,"VariantsController"); // for minifying purposes
export default VariantsController;