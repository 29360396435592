const controlCategories = ["org", "function"] as const;
type SelectControlCategory = typeof controlCategories[number];

type SelectControlId = string;

/**
 * The data representing a case selection control. Any selection on the
 * history stack containing a selection made by an in-graph selection control
 * will be popped of the stack before a new non-local case selection is
 * pushed on the stack.
 */
type SelectControlProfile = {
    category: SelectControlCategory;
    isInGraphControl: boolean;
    setState: (stateData: unknown) => void;
}

/**
 * A collection of case selection controls, accessible by their id
 */
class SelectControls extends Map<SelectControlId, SelectControlProfile> { }

/**
 * The selection made by a single case selection control. It contains
 * the id of the control, the selected cases and the data representing
 * the state of the control after it made the selection.
 */
type ControlSelection = {
    controlId: SelectControlId; // the case selection component which made the selection
    caseIds: number[]; // the selected cases
    controlState: unknown; // the state of the case selection component
}

/**
 * A case selection represents the state and the selected cases of possibly
 * multiple case selection controls, i.e. ControlSelection class objects,
 * of different categories. In each possible category, there can be one
 * case selection control state assigned.
 */
export class Selection extends Map<SelectControlCategory, ControlSelection> {
    copy() {
        const result = new Selection();
        for (const [key, val] of this) {
            result.set(key, { ...val });
        }
        return result;
    }
}

type GraphFilterId = string;
type GraphFilterSettings = unknown;
type GraphFilterProfile = {
    /**
     *
     * @param filterData The data (structure & contenent unknown to the interaction controller)
     * to set this filter. Set to default, if unknowns
     */
    setFilter: (filterData: GraphFilterSettings) => void;
    getFilterSettings: () => GraphFilterSettings;
}

class FilterSettings extends Map<GraphFilterId, GraphFilterSettings> {
    copy() {
        const result = new FilterSettings();
        for (const [key, val] of this) {
            if (val === Object(val)) {
                result.set(key, Object.assign({}, val)) // copy object
            }
            else {
                result.set(key, val); // primitives are copied
            }
        }
        return result;
    }
}

class GraphFilters extends Map<GraphFilterId, GraphFilterProfile> {
    getSettings() {
        const result = new FilterSettings();
        for (const [id, profile] of this) {
            result.set(id, profile.getFilterSettings());
        }
        return result;
    }
    setSettings(settings: FilterSettings) {
        for (const [id, profile] of this) {
            const setting = settings.get(id);
            profile.setFilter(setting);
        }
    }
}

const HISTORY_STACK_SIZE = 10;

type Stackitem = {
    selection: Selection;
    graphFilters: FilterSettings;
}

export class GraphInteractionController {
    selectControls: SelectControls = new SelectControls();
    graphFilters: GraphFilters = new GraphFilters();
    stackPtr = -1;
    stack: Stackitem[] = [];
    doSelect: (selection: Selection) => void

    constructor(doSelect: (selection: Selection) => void) {
        this.doSelect = doSelect;
        return;
    }

    registerSelector(controlId: SelectControlId, controlProfile: SelectControlProfile) {
        this.selectControls.set(controlId, controlProfile);
    }

    registerGraphFilter(filterId: GraphFilterId, filterProfile: GraphFilterProfile) {
        this.graphFilters.set(filterId, filterProfile);
    }

    clearStack() {
        this.stackPtr = -1;
        this.stack = [];
    }

    setFilter(filterId: GraphFilterId, settings: GraphFilterSettings) {
        const item = this.getTopOfStackCopy();
        const graphFilter = this.graphFilters.get(filterId)
        if (graphFilter) {
            item.graphFilters.set(filterId, settings);
            this.push(item);
            graphFilter.setFilter(settings);
        }
    }

    select(controlSelection: ControlSelection) {
        const control = this.selectControls.get(controlSelection.controlId);
        if (control) {

            // if not pushing an in-graph selection, remove all in-graph selections
            if (!control.isInGraphControl) {
                while (this.stackPtr >= 0 && this.hasInGraphSelection(this.stack[this.stackPtr].selection)) {
                    this.stackPtr--
                }
            }

            const item = this.getTopOfStackCopy();
            const currentControlSelection = item.selection.get(control.category);

            /* store new selection only if it is really a change */
            const isChange = currentControlSelection?.controlId !== controlSelection.controlId
                || currentControlSelection.caseIds.length !== controlSelection.caseIds.length
                || currentControlSelection.caseIds.some(caseId => !controlSelection.caseIds.includes(caseId))
            if (isChange) {
                item.selection.set(control.category, controlSelection)
                item.graphFilters = this.graphFilters.getSettings();
                this.push(item);
            }

            /* if in-graph selection were popped off the stack or a real change in selection, do selection */

            if (isChange || !control.isInGraphControl) {
                this.performSelection(item.selection, this.stackPtr >= 1 ? this.stack[this.stackPtr - 1].selection : undefined)
                this.graphFilters.setSettings(item.graphFilters)
            }
        }
    }

    private getTopOfStackCopy(): Stackitem {
        return (this.stackPtr !== -1)
            ? {
                selection: this.stack[this.stackPtr].selection.copy(),
                graphFilters: this.stack[this.stackPtr].graphFilters.copy(),
            }
            : {
                selection: new Selection(),
                graphFilters: new FilterSettings()
            }
    }

    push(item: Stackitem) {
        /* init stack or push item on stack */
        if (this.stackPtr === -1) {
            /* nothing on stack, init it */
            this.stack = [item];
            this.stackPtr = 0;
        } else {
            if (this.stackPtr === this.stack.length - 1) {
                /* at end of stack, limit size to 5 */
                if (this.stack.length >= HISTORY_STACK_SIZE) {
                    this.stack.shift();
                }
            } else {
                /* somewhere in stack, remove old states above the pointer */
                this.stack = this.stack.slice(0, this.stackPtr + 1);
            }
            this.stack.push(item);
            this.stackPtr = this.stack.length - 1;
        }
    }

    private performSelection(currentSelection: Selection,
        previousSelection: Selection | undefined = undefined,
        activeControlSelection: ControlSelection | undefined = undefined) {
        /**
         * with the exception of the control of the activeControlSelection, which initiated a change in the
         * selection and which is therefore "up to state", unselect the controls which were previously selected
         * and select those, which are in the current selection.
         */
        for (const category of controlCategories) {
            const previousControlIdOfCategory = previousSelection?.get(category)?.controlId;
            const currentControlIdOfCategory = currentSelection.get(category)?.controlId;

            /**
             * un-select previous control if different from current and differend from active control
             */
            if (previousControlIdOfCategory
                && previousControlIdOfCategory !== currentControlIdOfCategory
                && previousControlIdOfCategory !== activeControlSelection?.controlId) {
                this.selectControls.get(previousControlIdOfCategory)?.setState(undefined);
            }

            /**
             * select current control if different from acive control
             */
            if (currentControlIdOfCategory
                && currentControlIdOfCategory !== activeControlSelection?.controlId) {
                this.selectControls.get(currentControlIdOfCategory)?.setState(currentSelection.get(category)?.controlState);
            }
        }
        this.doSelect(currentSelection);
    }

    goBack() {
        if (this.stackPtr > 0) {
            const previousStackItem = this.stack[this.stackPtr];
            this.stackPtr--;
            const stackItem = this.stack[this.stackPtr];
            this.performSelection(stackItem.selection, previousStackItem.selection);
            this.graphFilters.setSettings(stackItem.graphFilters)

        }
        else if (this.stackPtr === 0) {
            this.stackPtr--;
        }
    }

    goFwd() {
        if (this.stackPtr < this.stack.length - 1) {
            const previousStackItem = this.stack[this.stackPtr];
            this.stackPtr++;
            const stackItem = this.stack[this.stackPtr];
            this.performSelection(stackItem.selection, previousStackItem.selection);
            this.graphFilters.setSettings(stackItem.graphFilters)
        }
    }

    /**
     * Display stack position and stack length. Stack length is displayed as a sum of external and
     * in-graph items.
     * @returns
     */
    stackState() {
        const localItems = this.stack.filter(item => this.hasInGraphSelection(item.selection)).length;
        const result =  `${this.stackPtr + 1}/` + (
            localItems > 0 ? `${this.stack.length-localItems}+${localItems}`:`${this.stack.length}`
        )
        return result;
    }

    get backwardPossible() {
        return this.stackPtr > 0;
    }

    get forwardPossible() {
        return this.stackPtr < this.stack.length - 1;
    }

    private hasInGraphSelection(selection: Selection) {
        return Array.from(selection.values()).find(i => {
            const controlProfile = this.selectControls.get(i.controlId);
            return controlProfile?.isInGraphControl
        }) !== undefined
    }

}

export type GraphInteractionControlProps = {
    interactionController: GraphInteractionController;
}

