import React from 'react';

import CaseFiltersController from '../classes/data_provision/CaseFiltersController.js';
import GraphController from '../classes/data_provision/GraphController.js';
import VariantsController from '../classes/data_provision/VariantsController.js';
import Project from '@insight/common/interface/Project.js';
import OrgStructureController from '../classes/data_provision/OrgStructureController.js';
import DataController from '../classes/data_provision/DataController.js';


type DataProviderProps = React.PropsWithChildren

export type InsightData = {
    project: Project;
    graph: GraphController['data'];
    variants?: VariantsController['data'];
    caseFilters?: CaseFiltersController['data'];
    orgStructure?: OrgStructureController['data'];
}

export const DataContext = React.createContext<InsightData | undefined>(undefined)

class LoadController {
    setState: (data: InsightData | undefined) => void
    graphController: GraphController = new GraphController();
    variantsController: VariantsController | undefined ;
    caseFiltersController: CaseFiltersController | undefined;
    orgStructureController: OrgStructureController | undefined;

    constructor(setState: (data: InsightData | undefined) => void) {
        this.setState = setState;
    }

    async load(project: Project, setProgress: (progress: number) => void) {
        // eslint-disable-next-line no-useless-catch
        try {
            const activeControllers: DataController<unknown>[] = []

            this.graphController.setFilename(project.processFilePath);
            let totalSize = await this.graphController.getLoadSize();
            activeControllers.push(this.graphController);

            this.variantsController = undefined;
            if (project.variantsFilePath) {
                this.variantsController = new VariantsController();
                this.variantsController.setFilename(project.variantsFilePath)
                activeControllers.push(this.variantsController);
                totalSize += await this.variantsController.getLoadSize();
            }

            this.caseFiltersController = undefined;
            if (project.caseFiltersFilePath) {
                /**
                 * casefilters are a special case. as they are saveable, their
                 * filepath is always part of the project. by accessing the size
                 * we find out, wether casefilters exist on the server.
                 */
                this.caseFiltersController = new CaseFiltersController({ saveable: true });
                this.caseFiltersController.setFilename(project.caseFiltersFilePath);
                const size = await this.caseFiltersController.getLoadSize();
                if (size > 0) {
                    activeControllers.push(this.caseFiltersController);
                    totalSize += size;
                }
            }

            this.orgStructureController = undefined
            if (project.orgsFilePath) {
                this.orgStructureController = new OrgStructureController({ saveable: false });
                this.orgStructureController.setFilename(project.orgsFilePath);
                activeControllers.push(this.orgStructureController);
                totalSize += await this.orgStructureController.getLoadSize();
            }

            /** setup progress reporting */
            let totalReceived = 0;
            const progressCallback = (length: number) => {
                totalReceived += length;
                console.log(`Size: ${totalSize} Received: ${totalReceived}`);
                const progress = totalReceived / totalSize;
                setProgress(progress);
            };

            /** load all files in parallel */
            const promises = activeControllers.map(ctrl => ctrl.load(progressCallback))
            const results = await Promise.allSettled(promises)
            results.forEach(r => {
                if (r.status === "rejected") {
                    throw r.reason;
                }
            })

            const newState:InsightData = {
                project,
                graph: this.graphController.data,
                variants: this.variantsController ? this.variantsController.data : undefined,
                caseFilters: this.caseFiltersController ? this.caseFiltersController.data : undefined,
                orgStructure: this.orgStructureController ? this.orgStructureController.data : undefined
            }
            this.setState(newState)

        } catch (error) {
            throw error;
            this.setState(undefined);
        }
    }
}


export const DataControllerContext = React.createContext<LoadController | undefined>(undefined);

export const DataProvider2: React.FC<DataProviderProps> = (props: DataProviderProps) => {
    React.useEffect(() => {
        console.log("*** Render DataProvider2");
    })
    const [state, setState] = React.useState<InsightData | undefined>(undefined);
    const [value,] = React.useMemo(() => {
        return [state, setState]
    }, [state])
    const [controller,] = React.useState<LoadController>(new LoadController(setState));
    return (
        <DataControllerContext.Provider value={controller}>
            <DataContext.Provider value={value}>
                {props.children}
            </DataContext.Provider>
        </DataControllerContext.Provider>
    );
}
