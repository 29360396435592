// see https://stackoverflow.com/questions/18904399/why-cant-i-set-a-javascript-functions-name-property

/**
 * Set the name property of a class explicitly so an uglyfier does not
 * destroy it. This is needed for the decyler / recycler in order to
 * rebuild objects of that class.
 * @param _class
 * @param name
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export default function setClassName(_class:Function, name:string) {
    Object.defineProperty(_class, "name", { writable: true, value:name });
}
