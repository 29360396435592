import { Slider } from '@blueprintjs/core';
import React, { useEffect, useMemo, useState } from 'react';
import log from "../Logger.js"

interface SliderProps {
    value: number
    max: number;
    onChange: (value: number) => void;
}

export type SliderParameters = {
    labelValues: number[],
    label_max: number,
    stepSize: number
}

type SliderValue = number;

export const ISSlider: React.FC<SliderProps> = (props: SliderProps) => {

    const sliderParameters = useMemo(() => {

        /** highest power of ten below the max slider value */
        const magnitude = Math.pow(10, Math.floor(Math.log10(props.max)));

        /** lowest multiple of magnitude above the max value*/
        const label_max = Math.ceil(props.max / magnitude) * magnitude;

        const tick = Math.max((label_max / (magnitude / 2) <= 10 ? magnitude / 2 : magnitude), 1);
        const labelValues = [1];
        let labelValue = tick;
        while (labelValue <= label_max) {
            labelValues.push(labelValue);
            labelValue += tick;
        }

        let stepSize = Math.max(1, tick / 5);
        if (labelValues.length <= 8) {
            stepSize = Math.max(1, tick / 10);
        }
        return {
            labelValues,
            label_max,
            stepSize,
        }
    }, [props.max]);

    const [sliderValue, setSliderValue] = useState<SliderValue>(props.value)

    useEffect(()=>{
        setSliderValue(props.value);
    },[props.value])

    return (
        <Slider
            min={1}
            max={sliderParameters.label_max}
            stepSize={sliderParameters.stepSize}
            labelValues={sliderParameters.labelValues}
            onChange={(value) => {
                // if (sliderValue === 1 && value > 1) value--;
                value -= value % sliderParameters.stepSize
                if (value === 0) value = 1;
                setSliderValue(value)
            }}
            onRelease={props.onChange}
            value={sliderValue}
            vertical={true}
        />
    );
}
