import React from 'react'
import { IntlProvider } from 'react-intl'
import Baseplate from './Baseplate.js'
import { ProgressBarOverlay, WidgetProvider } from './WidgetProvider.js';

import * as RequestController from "../classes/Request.js";
import { useActor } from "@xstate/react";
import { messages, localizationService } from "../classes/LocaleStateMachine.js";
import { DataProvider2 } from './DataProvider2.js';

export const AppContent = () => {

    const [state] = useActor(localizationService);
    const locale = state.context.locale;

    removeAndSetLanguageRequestModifier(locale);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <WidgetProvider>
                <ProgressBarOverlay />
                <DataProvider2>
                    <script src={"@hpcc-js/wasm/dist/graphviz.umd.js"} type="javascript/worker"></script>
                    <Baseplate pushMenu={true} />
                </DataProvider2>
            </WidgetProvider>
        </IntlProvider>
    )

    function removeAndSetLanguageRequestModifier(locale: string) {
        RequestController.removeRequestModificator("app");
        RequestController.setRequestModificator("app", (request) => {
            const langs: string[] = [locale];
            let q = 1.0;
            Object.keys(messages).forEach((lang) => {
                if (lang !== locale) {
                    q -= 0.05;
                    langs.push(`${lang};q=${q}`);
                }
            });
            const acceptedLanguages = langs.join(", ");
            request.headers = { ...request.headers, "Accept-Language": acceptedLanguages };
        });
    }
}
