import SortedArray from "collections/sorted-array.js";
import { RunTime } from "./run_time.js";
import { EdgeCase } from "./edge_case.js";

export type EdgeCasesData = number[][];

export class EdgeCases extends SortedArray<EdgeCase> {

    static validate(data: unknown): data is EdgeCasesData {
        return Array.isArray(data) &&
            data.every(c => Array.isArray(c) &&
                c.every(n => Number.isInteger(n)) &&
                (c.length & 1) &&  // odd length
                (c.length >= 3)
            )
    }

    cacheValid: boolean;
    __totalRunTime!: number;
    __avgRunTime!: number;
    __minRunTime!: number;
    __maxRunTime!: number;
    __traversals!: number;

    constructor(cases: EdgeCase[] = []) {
        super(
            cases,
            (c1, c2) => c1.case_id === c2.case_id,
            (c1, c2) => c1.case_id - c2.case_id
        );
        this.cacheValid = false;
        this.resetCache();
    }

    private resetCache() {
        this.__totalRunTime = -1;
        this.__avgRunTime = -1;
        this.__minRunTime = -1;
        this.__maxRunTime = -1;
        this.__traversals = -1;
        this.cacheValid = true;
    }

    private validateCache() {
        if (!this.cacheValid) {
            this.resetCache();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(q: any): EdgeCase | undefined {
        if (q.case_id === undefined) throw new Error("Expected query with case_id");
        return super.get(q);
    }

    initFromData(data: unknown): data is EdgeCasesData {
        if (EdgeCases.validate(data)) {
            data.forEach((caseData: number[]) => {
                const id = caseData.shift()!;
                if (Number.isInteger(id)) {
                    const runtimes: Array<RunTime> = [];
                    while (caseData.length > 0) {
                        const no = caseData.shift()!;
                        const ms = caseData.shift()!;
                        if (Number.isInteger(no) && Number.isInteger(ms)) {
                            runtimes.push(new RunTime(no, ms));
                        }
                    }
                    this.add(new EdgeCase(id, runtimes));
                }
            });
            this.cacheValid = false;
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): EdgeCasesData {
        return this.map<number[]>((_case) => {
            const runtimeData = _case.runtimes
                .map((rt) => [rt.no, rt.ms])
                .reduce((arr, rt) => {
                    arr.push(...rt);
                    return arr;
                }, []);
            const result = [_case.case_id, ...runtimeData];
            return result;
        });
    }

    /**
     * Add a number of traversals to a case given by the id.
     * @param {number} id
     * @param {number[]|number} runtimes
     */
    addCase(id: number, runtimes: Array<RunTime> | RunTime) {
        if (!(runtimes instanceof Array)) {
            runtimes = [runtimes];
        }
        let c = this.get({ case_id: id });
        if (c === undefined) {
            c = new EdgeCase(id, runtimes);
            this.add(c);
        } else {
            c.runtimes.push(...runtimes);
        }
        this.cacheValid = false;
        return c;
    }

    removeCase(_case: EdgeCase) {
        const result = this.delete(_case);
        this.cacheValid = false;
        return result;
    }

    removeRun(_case: EdgeCase, runidx: number) {
        _case.runtimes.splice(runidx, 1);
        if (_case.runtimes.length === 0) {
            this.removeCase(_case);
        }
        this.cacheValid = false;
        return;
    }

    getCase(id: number) {
        return this.get({ id });
    }

    values() {
        return this.array;
    }

    copy() {
        const cases = this.array.map((c) => {
            const _case = new EdgeCase(c.case_id, [...c.runtimes]);
            _case.__runtime = c.totalRuntime;
            _case.count = c.count;
            return _case;
        });
        const copy = new EdgeCases(cases);
        return copy;
    }

    /**
     * total run time of all cases on this edge
     */
    get totalRunTime() {
        this.validateCache();
        if (this.__totalRunTime === -1) {
            this.__totalRunTime = this.reduce((trt, _case) => trt + _case.totalRuntime, 0);
        }
        return this.__totalRunTime;
    }

    /**
     * Average runtime per case run on this edge
     */
    get avgRunTime() {
        this.validateCache();
        if (this.__avgRunTime === -1) {
            const { runs, totalTime } = this.array.reduce((t, c) => {
                c.runtimes.forEach(rt => {
                    t.runs++;
                    t.totalTime += rt.ms;
                })
                return t;
            }, { runs: 0, totalTime: 0 })
            this.__avgRunTime = totalTime / runs;
        }
        return this.__avgRunTime;
    }

    /**
     * Minimum case tuntime on this edge
     */
    get minRunTime() {
        this.validateCache();
        if (this.__minRunTime === -1) {
            this.__minRunTime = Math.min(...this.array.map(ec => ec.minRuntime));
        }
        return this.__minRunTime;
    }

    get maxRunTime() {
        this.validateCache();
        if (this.__maxRunTime === -1) {
            this.__maxRunTime = Math.max(...this.array.map(ec => ec.maxRuntime));
        }
        return this.__maxRunTime;
    }

    get traversals() {
        this.validateCache();
        if (this.__traversals === -1) {
            this.__traversals = this.reduce((sum, _case) => sum + _case.runtimes.length, 0);
        }
        return this.__traversals;
    }
}
