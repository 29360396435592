// import moment from "moment";
import { Duration } from "luxon";
const SECONDS_IN_A_DAY = 60 * 60 * 24;
export default function format_duration(ms_since_1970: number) {
    // eslint-disable-next-line no-useless-catch
    try {
        const days = Math.floor(ms_since_1970 / SECONDS_IN_A_DAY / 1000);
        const time_in_ms = (ms_since_1970 - days * SECONDS_IN_A_DAY * 1000);
        const time_string = (days > 0 ? days + "T " : "") + Duration.fromMillis(time_in_ms).toFormat("hh:mm:ss");
        return time_string;
    }
    catch (err) {
        throw err;
    }
}
