import { AxiosProgressEvent } from "axios";
import { sendRequest } from "../Request.js";
import Decycler, { Serializable } from "@insight/common/decycler/decycler.js";

function decamelize(str: string, separator: string): string {
    separator = typeof separator === "undefined" ? "_" : separator;

    return str
        .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
        .toLowerCase();
}

export interface DataControllerOptions {
    saveable: boolean;
}

export default abstract class DataController<DataType = void> {
    filepath: string | null;
    loadSize: number | null;
    data: DataType;
    saveable: boolean;
    dirty: boolean;

    static decycler = new Decycler();

    constructor(data: DataType, options = { saveable: false }) {
        this.filepath = null;
        this.loadSize = null;
        this.data = data;
        this.saveable = options.saveable;
        this.dirty = false;
    }

    get name(): string {
        return decamelize(this.constructor.name, "_");
    }

    setFilename(filename: string) {
        this.filepath = filename;
    }

    store(statusCB: (l: number) => void) {
        let result: Promise<void>;
        if (this.filepath !== null) {
            const query = new URLSearchParams();
            query.set("filepath", this.filepath);
            result = sendRequest<void, Serializable>("data/content?" + query.toString(), {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain",
                },
                data: this.toJSON(),
                onUploadProgress: (e: AxiosProgressEvent) => {
                    statusCB(e.loaded)
                },
            });
        }
        else {
            result = Promise.reject("Filepath not set");
        }
        return result;
    }

    getLoadSize(): Promise<number> {
        let result: Promise<number>;
        if (this.filepath !== null) {
            const query = new URLSearchParams();
            query.set("filepath", this.filepath);
            result = sendRequest<{ size: number }, void>("data/size?" + query.toString())
                .then(sizeData => {
                    this.loadSize = sizeData.size;
                    return sizeData.size;
                });
        }
        else {
            result = Promise.reject("Filepath not set");
        }
        return result;
    }

    load(statusCB: (l: number) => void) {
        if (this.filepath !== null) {
            let loadedSoFar = 0;
            const query = new URLSearchParams();
            query.set("filepath", this.filepath);
            return sendRequest<Serializable, void>(
                "data/content?" + query.toString(),
                {
                    onDownloadProgress: (e: AxiosProgressEvent) => {
                        const increment = e.loaded - loadedSoFar;
                        statusCB(increment);
                        loadedSoFar = e.loaded;
                    },
                }
            ).then(pojso => {
                this.dirty = false;
                this.data = this.fromJSON(pojso);
                return;
            })
            .catch(err=>{
                throw err;
            })
        }
        else {
            throw new Error("Filepath may not be null");
        }
    }

    fromJSON(pojso: Serializable): DataType {
        return pojso as DataType;
    }

    toJSON(): Serializable {
        if (this.data) {
            return this.data as Serializable;
        }
        else {
            return {} as Serializable;
        }
    }
}

