import SortedArray from "collections/sorted-array.js";
import { DragPositions } from "../../components/CaseSelection/SelectTreeLabel.js";
import { CaseFilter } from "./CaseFilter.js";

export class CaseFilterCollection {
    multiRootedTree: CaseFilter[];
    filterRegistry: SortedArray<CaseFilter> = new SortedArray<CaseFilter>([], (f1, f2) => { return f1.id === f2.id }, (f1, f2) => { return f1.id - f2.id });
    dirty: boolean;

    constructor(filters: CaseFilter[] | undefined, dirty: boolean) {
        this.multiRootedTree = filters !== undefined ? [...filters] : [];
        const addFilters = (filters: CaseFilter[]) => {
            filters.forEach(f => {
                this.filterRegistry.add(f);
                addFilters(f.children)
            })
        }
        addFilters(this.multiRootedTree);
        this.dirty = dirty
    }

    add(filter: CaseFilter, parent: CaseFilter | null) {
        const existing = this.filterRegistry.get(filter);
        if (existing === undefined) {
            if (parent === null) {
                const treeClone = [...this.multiRootedTree];
                treeClone.push(filter);
                this.multiRootedTree = treeClone;
            }
            else {
                parent = this.updateTreeFor(parent);
                parent.children.push(filter);
            }
            this.filterRegistry.add(filter);
        }
        else {
            throw new Error(`filter exists`);
        }
    }

    remove(filter: CaseFilter) {
        const existing = this.filterRegistry.get(filter);
        if (existing !== undefined) {
            let filterArray: CaseFilter[] = this.multiRootedTree;
            if (filter.parent !== null) {
                const parent = this.updateTreeFor(filter.parent);
                filterArray = parent.children;
            }
            const pos = filterArray.findIndex(c => c === filter);
            if (pos >= 0) {
                filterArray.splice(pos, 1);
                this.filterRegistry.delete(filter);
            }
            else {
                throw new Error(`Did not find existing`)
            }
        }
        else {
            throw new Error(`did not find existing`);
        }
    }

    move(filter: CaseFilter, position: DragPositions, target: CaseFilter) {
        if (this.filterRegistry.get(filter) !== undefined && this.filterRegistry.get(target) !== undefined) {

            filter = this.updateTreeFor(filter); // replace existing node in tree with shallow clone

            /** remove from original position in tree */
            let filterArray: CaseFilter[] = this.multiRootedTree;
            if (filter.parent !== null) {
                const parent = this.updateTreeFor(filter.parent);
                filterArray = parent.children
            }
            const filterIdx = filterArray.findIndex(f => f === filter); // should always succeed
            if (filterIdx >= 0) {
                filterArray.splice(filterIdx, 1);
            }
            else {
                throw new Error(`cannot find original pos of item to be moved`)
            }

            /** determine new position in tree and insert there */
            let targetArray: CaseFilter[] = [];
            let newParent: CaseFilter | null = null;
            let insertPos = -1;

            target = this.updateTreeFor(target);
            switch (position) {
                case "center": {
                    targetArray = target.children;
                    newParent = target;
                    insertPos = targetArray.length;
                    break;
                }
                case "above":
                case "below": {
                    if (target.parent !== null) {
                        newParent = this.updateTreeFor(target.parent);
                        targetArray = newParent.children;
                    }
                    else {
                        targetArray = this.multiRootedTree
                    }

                    const tmp = targetArray.indexOf(target);
                    if (tmp < 0) throw new Error(`cannot find position of target`);
                    insertPos = tmp + (position === "below" ? 1 : 0);
                    break;
                }
            }

            /** add to new neighbours */
            targetArray.splice(insertPos, 0, filter);
            filter.parent = newParent
        }
        return filter;
    }

    asArray(): CaseFilter[] {
        return this.multiRootedTree
    }

    /**
     * Mutates the tree roots and the passed filter by replacing them with a copy. Also
     * used to update edited nodes.
     * @param filter The case filter to be mutated
     * @returns
     */
    updateTreeFor(filter: CaseFilter): CaseFilter {
        // 1. make sure passed filter exists
        const existing = this.filterRegistry.get(filter);
        if (existing !== undefined) {
            // 2. clone the tree roots
            const treeClone = [...this.multiRootedTree];
            // 3. clone the filter
            const clone = filter.cloneShallow();
            // 4. delete existing from registry and add clone
            if (this.filterRegistry.delete(existing)) {
                this.filterRegistry.add(clone);
                // 5. in cloned tree, replace existing by clone
                const filterArray = existing.parent === null ? treeClone : existing.parent.children;
                const pos = filterArray.findIndex(f => f === existing);
                if (pos >= 0) {
                    filterArray[pos] = clone;
                    // 6. update parents of clone's children
                    clone.children.forEach(c => c.parent = clone);
                    // 7. replace tree roots with their clone
                    this.multiRootedTree = treeClone
                    return clone;
                }
                else {
                    throw new Error(`Did not find existing in tree.`)
                }
            }
            else {
                throw new Error(`Existing could not be deletnd from registry.`);
            }
        }
        else {
            throw new Error(`Did not find existing filter`);
        }
    }
}