import SortedArraySet from "collections/sorted-array-set.js";
import assert from "assert";

import AttributedGraphElement, { AttributedGraphElementData, GraphElementAttributes } from "./attributedgraphelement.js";
import GraphElement from "./graphelement.js";

import Edge from "./edge.js";
import Graph from "./graph.js";

export type VertexData = AttributedGraphElementData;

export default class Vertex extends AttributedGraphElement {
    static edgesEqual = Edge.equals;
    static compareEdges = Edge.compare;
    static createEdge = Edge.createFromData;

    static createFromData(data: unknown, graph: Graph): Vertex {
        const vertex: Vertex = Object.create(Vertex.prototype);
        vertex.setGraph(graph);
        vertex.initFromData(data);
        return vertex;
    }

    static validate(data: unknown): data is VertexData {
        return AttributedGraphElement.validate(data)
    }

    outgoing!: SortedArraySet<Edge>;
    incoming!: SortedArraySet<Edge>;

    constructor(attributes: GraphElementAttributes) {
        super(attributes);
        this._initVertex(); // call #__init of Vertex, not of any derived class
    }

    declare ["constructor"]: typeof Vertex & typeof AttributedGraphElement & typeof GraphElement;

    /**
     * setup collections for incoming and outgoing edges
     */
    _initVertex() {
        this.outgoing = new SortedArraySet<Edge>([], this.constructor.edgesEqual, this.constructor.compareEdges);
        this.incoming = new SortedArraySet<Edge>([], this.constructor.edgesEqual, this.constructor.compareEdges);
    }

    initFromData(data: unknown): data is VertexData {
        if (super.initFromData(data) &&
            Vertex.validate(data)) {
            this._initVertex();
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): VertexData {
        return {
            ... super.__serializableObject(),
        }
    }

    copy(copy: Vertex) {
        super.copy(copy as AttributedGraphElement);
        copy._initVertex(); // call #__init of Vertex, not of any derived class
        return copy;
    }

    addIncoming(e: Edge) {
        this.incoming.add(e);
    }

    removeIncoming(e: Edge) {
        assert(this.constructor.equals(this, e.to), `*** Not an incoming edge`);
        const result = this.incoming.delete(e);
        assert(result, `Cannot remove incoming edge ${e.__id}.`);
        return result;
    }

    addOutgoing(e: Edge) {
        this.outgoing.add(e);
    }

    removeOutgoing(e: Edge) {
        assert(this.constructor.equals(this, e.from), `*** Not an outgoing edge.`);
        const result = this.outgoing.delete(e);
        assert(result, `Cannot remove outgoing edge ${e.__id}.`);
        return result;
    }
}
