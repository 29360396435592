import React, { act, PropsWithChildren } from 'react';
import { Overlay2, Classes, ProgressBar, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import log from 'loglevel';

interface iWidgetContext {
    progress: number;
    isOpen: boolean;
    label: string;
    activity: boolean;
}

// interface Activity {
//     value: boolean;
// }

/**
 *   type Dispatch<A> = (value: A) => void;
 *   type SetStateAction<S> = S | ((prevState: S) => S);
 */
interface iWidgetControlContext {
    overlay: {
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setProgress: React.Dispatch<React.SetStateAction<number>>;
        setLabel: React.Dispatch<React.SetStateAction<string>>;
    }
    spinner: {
        // setActivity: React.Dispatch<React.SetStateAction<Activity>>;
        // setActivity: (show: boolean, cb: () => void) => void;
        // setActivity: React.Dispatch<React.SetStateAction<boolean>>;
        setActivity: (show: boolean) => void;
    }
}

export const WidgetContext = React.createContext<iWidgetContext | undefined>(undefined);
export const WidgetControlContext = React.createContext<iWidgetControlContext | undefined>(undefined);

export const WidgetProvider: React.FC<PropsWithChildren<object>> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [label, setLabel] = React.useState("***");
    const [activity, setActivity] = React.useState(false);
    const timerId = React.useRef(-1);
    /**
     * memoize the SetProgressContext value so that the components subscribing to the progess setting
     * mehods don't need to redraw on each context update.
     */
    const value = React.useMemo(() => ({
        overlay: {
            setIsOpen, setProgress, setLabel,
        }, spinner: {
            setActivity: (show: boolean) => {
                if (timerId.current === -1) {
                    if (show) {
                        timerId.current = setTimeout(() => {
                            setActivity(true)
                            timerId.current = -1;
                        }, 300) as unknown as number
                    }
                    else {
                        setActivity(false)
                    }
                }
                else {
                    if (!show) {
                        clearTimeout(timerId.current);
                        timerId.current = -1;
                        setActivity(false)
                    }
                }
            }
        }
    }), [setIsOpen, setProgress, setLabel, setActivity]);

    return (
        <WidgetControlContext.Provider value={value}>
            <WidgetContext.Provider value={{ isOpen, progress, label, activity }}>
                {props.children}
            </WidgetContext.Provider>
        </WidgetControlContext.Provider>
    );
};

/**
 * ProgressBarOverlay Widget
 * @returns
 */
export const ProgressBarOverlay: React.FC = () => {
    const widgetContext = React.useContext(WidgetContext);

    if (!widgetContext) {
        return null;
    }

    const OVERLAY_EXAMPLE_CLASS = "progress-overlay";
    const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        OVERLAY_EXAMPLE_CLASS,
    );
    return (
        <Overlay2
            className={Classes.OVERLAY_SCROLL_CONTAINER}
            hasBackdrop={true}
            usePortal={true}
            isOpen={widgetContext.isOpen}
        >
            <div className={classes}>
                <div>{widgetContext.label}...</div>
                <ProgressBar intent="primary" value={widgetContext.progress} />
            </div>
        </Overlay2>
    );
}

/**
 * Activity Spinner Widget
 * @returns
 */
export const Activity2: React.FC = () => {
    const widgetContext = React.useContext(WidgetContext);

    /** no effect dependencies => run once per and after render */
    React.useEffect(() => {
        log.debug("*** Render Activity2")
    })

    return <>
        {widgetContext !== undefined && widgetContext.activity &&
            <Spinner size={26} intent={"danger"} />
        }
    </>

}